import React from 'react';
import ReactPlayer from 'react-player';
import "../style/video-metrics.scss";
import "../style/video.scss";
import Timeline from './Timeline';
import AnimateIn from './AniamteIn';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VideoPlayer = ({ metrics }) => {
  return (
    <section className='player-section'>

      <AnimateIn>
        <div className='video-timeline'>
          <div className='video-metrics'>
            <ul>
              {metrics.map((metric) => (
                <li key={metric.title}>
                  <FontAwesomeIcon className='icon' color='#48dacb' icon={metric.icon} size='4x' />
                  <div className='text'>
                    <div className='title'>
                      <h3>{metric.number}</h3>
                      <span>{metric.title}</span>
                    </div>
                    <p className='description highlight-paragraph'>{metric.description}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className='video-player'>
            <video className='video-bg' autoPlay muted loop controls>
              <source src={"https://zoner.b-cdn.net/georgemoore-vid.mp4"} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </AnimateIn>
    </section>
  );
}

export default VideoPlayer;
