import React, { useRef, useEffect } from "react";
import useElementOnScreen from "./hooks/useElementOnScreen";

const AnimateIn = ({ children, classes }) => {
  const ref = useRef(null);
  const onScreen = useElementOnScreen(ref);
  return (
    <div
      ref={ref}
      className={classes}
      style={{
        opacity: onScreen ? 1 : 0,
        translate: onScreen ? "none" : "0 2rem",
        transition: "600ms ease-in-out",
        animation: 'slideInUp 2s',
        animationnDelay: '3s'
      }}
    >
      {children}
    </div>
  );
};

export default AnimateIn;

