import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import "../style/quotes.scss";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Fade from 'react-reveal/Fade';

const Quotes = ({ quotes }) => {

  return (

    <section className='quotes'>
      <Fade bottom>
        <div className='quotes__header'>
          <p>{quotes.description}</p>
        </div>
      </Fade>
      <div className="quotes__list">
        <Fade bottom>
          {quotes.quotes.map((quote) => (
            <div key={quote.name} className="quote card-item">
              <p>{quote.quoteText}</p>
              <div className='author'>
                <div className='quote__image'>
                  <GatsbyImage image={getImage(quote.image)} alt={quote.name} />
                </div>
                <div className='author__info'>
                  <h3>{quote.name}</h3>
                  <p className='paragraph-subtle'>{quote.degree}</p>
                  <p className='paragraph-subtle bold'>{quote.university}</p>
                </div>

              </div>
            </div>

          ))}  </Fade>
      </div>

    </section>

  )
};

export default Quotes;
