import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from 'react-reveal/Fade';
import "../../style/info-panel.scss";
import { getImage } from "gatsby-plugin-image";
import { GatsbyImage } from "gatsby-plugin-image";


export default function InfoPanel({ infoPanel }) {
  const { title, description, about, image } = infoPanel
  return (

    <section className="info-panel">
      <Fade bottom>
        <div className='info-panel__header'>
          <p>{description}</p>
          <h2 className="fs-primary-heading">{title}</h2>
        </div>
        <div className="info">
          <GatsbyImage
            image={getImage(image)}
            placeholder="tracedSVG"
            objectFit={"cover"}
            class="info-img"
            layout="fullWidth"
            // This is a presentational image, so the alt should be an empty string
            alt=""
            formats={["auto", "webp", "avif", 'jpg', 'png']}
          />
          <div className='card-item' key="george moore">
            <p>{about} </p>
          </div>
        </div>
      </Fade>
    </section >

  );
}
