import React from 'react'
import "../style/timeline.scss";
import AnimateIn from './AniamteIn';
import ReactMarkdown from 'react-markdown'


const dates = [
  { date: "11 Febuary", desc: "Online application opens" },
  { date: "27 March", desc: "Deadline for completed applications" },
  { date: "April", desc: "Application assessment" },
  { date: "May", desc: "Interviews & Notification of Scholarships" },
  { date: "June", desc: "Award event/pre-departure programme" }
];



const Timeline = ({
  timelineSteps,
  registerPanel,
  scholarshipPanel
}) => (
  <section className='timeline-wrapper'>
    <AnimateIn>
      <div className='timeline__heading'>
        <h2 className='fs-primary-heading'>Timeline 2024</h2>
      </div>

      <div className="timeline">
        <div >
          {timelineSteps.map((step, index) =>
            <div className="wrapper">
              <div className="circle"><div className="circle-text">{`0${index + 1}`}</div></div>
              <div className="date">
                <p className="title">{step.title}</p>
                <p className="subtitle">{step.subtitle}</p>
              </div>
            </div>
          )}
        </div>
        <div className='sub-wrapper'>
          <div className='description card-item'>
            <h3>{scholarshipPanel.title}</h3>
            <ReactMarkdown>{scholarshipPanel.description}</ReactMarkdown>
          </div>

          <div className='register card-item'>
            <h3>{registerPanel.title}</h3>
            <ReactMarkdown>{registerPanel.description}</ReactMarkdown>

          </div>
        </div>
      </div>


    </AnimateIn>
  </section>

);

export default Timeline
